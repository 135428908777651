/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'), url('./fonts/roboto-v20-latin-300.woff2') format('woff2');
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url('./fonts/roboto-v20-latin-regular.woff2') format('woff2');
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'), url('./fonts/roboto-v20-latin-500.woff2') format('woff2');
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'), url('./fonts/roboto-v20-latin-700.woff2') format('woff2');
}

html,
body {
  font-family: 'Roboto', sans-serif;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: fixed;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
  overflow: 'hidden';
  -webkit-overflow-scrolling: touch; /* enables “momentum” (smooth) scrolling */
}

.mapboxgl-canvas,
.ol-layer {
  overflow: 'hidden';
  top: 0;
}
